.date-range-picker {
    position: relative;
}

.date-picker-input {
    display: flex;
    align-items: center;
}

.date-picker-input input {
    border: 1px solid #ccc;
    padding: 8px;
    width: 240px;
    height: 37px;
    color: var(--bs-body-color);
    font-weight: 400 !important;
    font-family: 'poppins';
    outline: none; /* Removes the focus outline */
    box-shadow: none;
    cursor: pointer;
}

.date-picker-icons {
    margin-left: -23px;
    color: var(--bs-body-color);
}

.date-range-calendar {
    position: absolute;
    z-index: 1000;
    display: flex;
    gap: 10px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
}
